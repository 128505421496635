import { observable, action, computed, toJS, makeObservable } from 'mobx';
import { makeAuthenticatedRequest } from 'utils/API';
import AuthStore from './AuthStore';


export type ExclusionListResponse = {
    id: string,
    resource_list: string[],
    account_id: string,
    account_name: string
}

export type ExclusionListRequest = {
    id: string,
    resource_list: string,
    selected_account_ids: string[]
}

export class ExclusionListsStore {
    public _loading: boolean;

    public selectedEntry?: string;
    public creatingEntry?: number;
    public currentAccountID: string;
    public currentExclusionList: string;

    private _data: any[];

    constructor() {
        makeObservable(this, {
            // @ts-ignore
            _data: observable,
            _loading: observable,

            data: computed,
            loading: computed,
            selectedEntry: observable,
            creatingEntry: observable,
            currentAccountID: observable,
            currentExclusionList: observable,

            encodeSpecialCharacters: action,
            decodeSpecialCharacters: action,
            setSelectedEntry: action,
            clearSelectedEntry: action,
            onApiFailure: action,
            onLoadDataSuccess: action,
            onLoadByIdDataSuccess: action, 
            loadData: action,
            loadDataById: action,
            onApiFailureEntryCreate:action,
            create: action,
            update: action,
            delete: action,
        });

        this._loading = false;
        this._data = [];
        this.selectedEntry = '';
        this.creatingEntry = 0;
        this.currentAccountID = '';
        this.currentExclusionList = '';
    }

    public get loading(): boolean {
        return toJS(this._loading);
    }

    public get data(): string[] {
        return toJS(this._data);
    }

    setSelectedEntry = (entry: string) => {
        this.selectedEntry = entry;
    }

    clearSelectedEntry = () => {
        this.selectedEntry = undefined;
    }

    onApiFailure = (e: Error) => {
        this._loading = false;
        throw e;
    }

    onApiFailureEntryCreate = (e: Error) => {
        const errorMessageJSON = JSON.parse(e.message);
        const errorMessageText = Object.values(errorMessageJSON)[0]

        this._loading = false;
        throw errorMessageText;
    }

    onLoadDataSuccess = ( response: ExclusionListResponse[] ) => {
        this._data = [];
        if(response.length > 0){
            this._data = response[0].resource_list;
            this.currentExclusionList = response[0].id
            for (let i=0; i<this._data.length; i++) {
                this._data[i] = this.decodeSpecialCharacters(this._data[i])
            }
        }else{
            this.currentExclusionList = '';
        }
        this._loading = false;
    }



    onLoadByIdDataSuccess = ( response: ExclusionListResponse[] ) => {
        this._data = [];
        if(response.length > 0){
            this._data = response[0].resource_list;
            this.currentExclusionList = response[0].id
            for (let i=0; i<this._data.length; i++) {
                this._data[i] = this.decodeSpecialCharacters(this._data[i])
            }
        }else{
            this.currentExclusionList = '';
        }
        this._loading = false;
    }


    loadData = (): Promise<any> => {
        this._loading = true;
        var url_account_id = AuthStore.user?.account_id;
        if(this.currentAccountID != ""){
            url_account_id = this.currentAccountID;
        }
        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${url_account_id}/exclusion_lists?include_subaccounts=false`, 
            options: { method: 'GET' }
        })
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);
    }

    loadDataById = (id: any): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${id}/exclusion_lists?include_subaccounts=false`, 
            options: { method: 'GET' }
        })
            .then(this.onLoadByIdDataSuccess)
            .catch(this.onApiFailure);
    }

    public encodeSpecialCharacters(data: string):string {
        let retValTemp1 = data.replaceAll(",","\\;");
        let retVal = retValTemp1.replaceAll(" ","\\|");
        return retVal;
    }

    public decodeSpecialCharacters(data: string):string {
        let retValTemp1 = data.replaceAll("\\;",",");
        let retVal = retValTemp1.replaceAll("\\|"," ");
        return retVal;
    }

    create = (data: string): Promise<any> => {
        this._loading = true;
        var postVal = this.encodeSpecialCharacters(data);
        for (let i=0; i<this._data.length; i++) {
            postVal = postVal + ", "
            postVal = postVal + this.encodeSpecialCharacters(this._data[i])
        }
        var url_account_id = '';
        if(AuthStore.user?.account_id){
            url_account_id = AuthStore.user?.account_id;
        }
        if(this.currentAccountID != ""){
            url_account_id = this.currentAccountID;
        }
        let selected_accounts: string[] = [ url_account_id ];
        var listReq:ExclusionListRequest = {id:this.currentExclusionList,resource_list:postVal,selected_account_ids:selected_accounts}
        const mydata = JSON.stringify(listReq);
        return makeAuthenticatedRequest({
            url: `/api/v2/exclusion_lists`, 
            options: { method: 'PUT' },
            data: listReq
        })
            .then(this.loadData)
            .catch(this.onApiFailureEntryCreate);
    }

    update = (data: string): Promise<any> => {
        this._loading = true;
        var postVal = '';
        for (let i=0; i<this._data.length; i++) {
            if(i != 0){
                postVal = postVal + ", "
            }
            if(this.selectedEntry == this._data[i]){
                postVal = postVal + this.encodeSpecialCharacters(data);
            }else{
                postVal = postVal + this.encodeSpecialCharacters(this._data[i]);
            }
        }
        var url_account_id = '';
        if(AuthStore.user?.account_id){
            url_account_id = AuthStore.user?.account_id;
        }
        if(this.currentAccountID != ""){
            url_account_id = this.currentAccountID;
        }
        let selected_accounts: string[] = [ url_account_id ];
        var listReq:ExclusionListRequest = {id:this.currentExclusionList,resource_list:postVal,selected_account_ids:selected_accounts}

        return makeAuthenticatedRequest({
            url: `/api/v2/exclusion_lists`, 
            options: { method: 'PUT' },
            data: listReq
        })
            .then(this.loadData)
            .catch(this.onApiFailure);
    }

    delete = (data: string): Promise<any> => {
        this._loading = true;
        var postVal = '';
        var firstEntry = 0;
        for (let i=0; i<this._data.length; i++) {
            if(data != this._data[i]){
                if(firstEntry == 1){
                    postVal = postVal + ", "
                }
                firstEntry = 1;
                postVal = postVal + this.encodeSpecialCharacters(this._data[i])
            }
        }
        var url_account_id = '';
        if(AuthStore.user?.account_id){
            url_account_id = AuthStore.user?.account_id;
        }
        if(this.currentAccountID != ""){
            url_account_id = this.currentAccountID;
        }
        let selected_accounts: string[] = [ url_account_id ];
        var listReq:ExclusionListRequest = {id:this.currentExclusionList,resource_list:postVal,selected_account_ids:selected_accounts}
        if(postVal == ""){
            return makeAuthenticatedRequest({
                url: `/api/v2/accounts/${url_account_id}/exclusion_lists`, 
                options: { method: 'DELETE' }
            })
                .then(this.loadData)
                .catch(this.onApiFailure);
        }else{
            return makeAuthenticatedRequest({
                url: `/api/v2/exclusion_lists`, 
                options: { method: 'PUT' },
                data: listReq
            })
                .then(this.loadData)
                .catch(this.onApiFailure);
        }
    
    }

}

const STORE = new ExclusionListsStore();

export default STORE;