import { observable, action, makeObservable } from 'mobx';
import { makeAuthenticatedRequest } from 'utils/API';
import AuthStore, { User } from './AuthStore';

class UserProfileStore {
    loading: boolean;

    constructor() {
        makeObservable(this, {
            loading: observable,
            updateProfile: action
        });

        this.loading = false;
    }

    updateProfile = (data: User): Promise<any> => {
        this.loading = true;


        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${AuthStore.user?.account_id}/users/${AuthStore.user?.id}`, 
            options: { method: 'PATCH' },
            data
        })
            .then((data: User) => {
                AuthStore.setUserData(data);
                this.loading = false;
            })
            .catch(() => {
                this.loading = false;
            });
    }
}

const STORE = new UserProfileStore();

export default STORE;