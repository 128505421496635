import React from 'react';
import { inject, observer } from 'mobx-react';
import { Link, NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { FaUsersCog, FaUsers } from 'react-icons/fa';
import { BiShieldQuarter } from "react-icons/bi"
import { MdDevices } from "react-icons/md";
import TuneIcon from '@mui/icons-material/Tune';
import InstallDesktopIcon from '@mui/icons-material/InstallDesktop';
import ErrorIcon from '@mui/icons-material/Error';
import HomeIcon from '@mui/icons-material/Home';

import './Sidebar.scss'
import PROPERTIES from 'properties';
import { styled } from '@mui/material';

const { VENDOR_INSTALL, SETTINGS, EVENTS, HOME: DASHBOARD } = PROPERTIES

type SidebarProps = {
    AuthStore?: any;
    isOpen: boolean;
    toggleMenuSidebar: () => void;
};

const Sidebar = ({ isOpen, AuthStore, toggleMenuSidebar }: SidebarProps) => {
    const { user } = AuthStore;
    
    return (
        <aside
            className={classnames({
                'main-sidebar': true,
                'sidebar-dark-danger': true,
                'elevation-4': true,
                'sidebar-open': isOpen,
                'sidebar-closed': !isOpen
            })}
            style={{
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: '#222227', 
                border:'1px #32334A',
                minHeight: '100vh',
                position: 'fixed',

            }}
        >
            <BrandContainer className="brand-link">
                <Link to="/" style={{border:'1px #32334A' }}>
                    <img
                        src="/img/arms-logo-new.png"
                        alt="ARMS Cyber"
                        className="brand-image"
                        style={{opacity: '.8'}}
                    />
                    <BrandSpan 
                        className="brand-text font-weight-light"
                        style={{fontSize: 16, fontWeight: 700}}
                    >Arms Cyber</BrandSpan>
                </Link>
                <CloseSidebarButton onClick={() => toggleMenuSidebar()}>
                    <i className="fas fa-bars" />
                </CloseSidebarButton>
            </BrandContainer>
            <div className="sidebar" style={{ flex: 1 }}>
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                        <img
                            src={user.picture || '/img/default-profile.png'}
                            className="img-circle elevation-2"
                            alt="User"
                            style={{height: 50, width: 50}}
                        />
                    </div>
                    <div className="info">
                        <Link to="/profile" className="d-block">
                            {user.first_name}&nbsp;{user.last_name}
                            <p style={{ color: 'grey', fontSize: 12}}>{user.role}</p>
                        </Link>
                        
                    </div>
                </div>
                <nav className="mt-2" style={{overflowY: 'hidden'}}>
                    <ul
                        className="nav nav-pills nav-sidebar flex-column"
                        role="menu"
                    >
                        <li className="nav-item">
                            <NavLink end className="nav-link custom-link-active" to="/">
                                <HomeIcon color={'primary'} fontSize='small' style={{marginLeft: -3}}/>
                                <p style={{ marginLeft: 9 }}>{DASHBOARD.TITLE}</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link custom-link-active" to="/endpoints">
                                <MdDevices color="#dc3545" />
                                <p style={{ marginLeft: 10 }}>Endpoints</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link custom-link-active" to="/alerts">
                                <ErrorIcon color={'primary'} fontSize='small' style={{marginLeft: -2, marginRight: 8}} />
                                <p>{EVENTS.TITLE}</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link custom-link-active" to="/settings">
                                <TuneIcon color={'primary'} fontSize='small' style={{marginLeft: -2, marginRight: 8}} />
                                <p>{SETTINGS.TITLE}</p>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link custom-link-active" to="/setup">
                                <InstallDesktopIcon color={'primary'} fontSize='small' style={{marginRight: 8}} />
                                <p>{VENDOR_INSTALL.TITLE}</p>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
            { AuthStore.isAdmin && (
                <div className="sidebar-footer" style={{paddingTop: 100, paddingLeft: 10, paddingRight: 10}}>
                    <nav className="mt-2" style={{overflowY: 'hidden', borderTop: '2px solid grey', paddingTop: 20, marginBottom: 30}}>
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            role="menu"
                        >
                            <li className="nav-item">
                                <NavLink className="nav-link custom-link-active" to="/accounts">
                                    <FaUsersCog color="#dc3545" size={30}/>
                                    <p style={{marginLeft: 20, fontSize: 20}}>Accounts</p>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link custom-link-active" to="/users">
                                    <FaUsers color="#dc3545" size={30}/>
                                    <p style={{marginLeft: 20, fontSize: 20}}>Users</p>
                                </NavLink>
                            </li>
                        </ul>
                    </nav>
                </div>
            )}
        </aside>
    );
};

export default inject('AuthStore')(observer(Sidebar));

const BrandContainer = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between'
}))

const BrandSpan = styled('span')(({theme}) => ({
    color: 'rgba(255, 255, 255, 0.75)',

    '&:hover': {
        color: 'white'
    }
}))

const CloseSidebarButton = styled('button')(({theme}) => ({
    background: 'transparent',
    border: 'none',
    outline: 'none',
    color: 'rgba(255, 255, 255, 0.75)',

    '&:hover': {
        color: 'white'
    }
}))
