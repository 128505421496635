import React from 'react';
import {inject, observer} from 'mobx-react';
import {useParams} from 'react-router';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    TimeScale,
    TimeSeriesScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import 'chartjs-adapter-moment';
import SmallBox from 'components/SmallBox';
import {toastFail} from 'utils/Toast';
import {Doughnut, Bar} from 'react-chartjs-2'
import EndpointAlertsTable from 'views/main/home/EndpointAlertsTable';
import ProcessesTableMonitoring from './ProcessesTableMonitoring';
import {BsCheckLg, BsXCircleFill} from 'react-icons/bs'
import alertsIcon from '../../../../utils/icons/alert-icon-monitoring.svg';
import './Monitoring.scss';
import moment from 'moment';
import StyledToggleButtonGroup from 'components/StyledToggleButtonGroup';
import { StyledSwitch } from 'views/main/home/Home';
import { Container, FormControlLabel, styled } from '@mui/material';
import { Category, Severity } from 'stores/AlertsStore';
import { categoriesFilterOptions, severitiesFilterOptions } from 'views/main/events/Events';
import { useAlertsStoreContext } from "stores/AlertsProvider";
import PROPERTIES from 'properties';

const { MONITORING } = PROPERTIES

type EndpointMonitoringProps = {
    DeviceDetailStore?: any;
    WindowsConfigStore?: any;
    id?: string;
    AlertsStore?: any;
}

type EnpointMonitoringState = {
    displayOverTime: boolean
    alertsSeverityFilter: Severity
    alertsCategoryFilter: Category
}


class EndpointMonitoring extends React.Component<EndpointMonitoringProps, any> {
    state: EnpointMonitoringState = {
        displayOverTime: false,
        alertsSeverityFilter: this.props.AlertsStore.severities,
        alertsCategoryFilter: "detection"
    }

    componentDidMount() {
        const { DeviceDetailStore, WindowsConfigStore, id } = this.props;

        WindowsConfigStore.loadData();

        DeviceDetailStore.loadData(id)
            .catch(() => toastFail('Failed to load the device'))

        ChartJS.register(
            CategoryScale,
            LinearScale,
            TimeScale,
            TimeSeriesScale,
            BarElement,
            LineElement,
            PointElement,
            ArcElement,
            Title,
            Tooltip,
            Legend
        );
    }

    componentDidUpdate(prevProps: Readonly<EndpointMonitoringProps>, prevState: Readonly<EnpointMonitoringState>) {
        if (this.state.alertsSeverityFilter !== prevState.alertsSeverityFilter && this.state.alertsSeverityFilter === 'siem')
            this.setState({ alertsCategoryFilter: 'all' })
    }

    onAssignPolicy = (e: any) => {
        const {DeviceDetailStore} = this.props;

        DeviceDetailStore.assignPolicy(e.target.value);
    }
    onAssignServerPolicy = (e: any) => {
        const { DeviceDetailStore } = this.props;

        DeviceDetailStore.assignServerPolicy(e.target.value);
    }

    private onAssignRansomwareProtection(value: boolean) {
        const { DeviceDetailStore } = this.props
        DeviceDetailStore.assignRansomWareProtection(value)
    }

    private onSelectAlertsSeverity = (selection: string) => {
        this.setState({alertsSeverityFilter: selection})
    }

    private onSelectAlertsCategory = (selection : string) => {
        this.setState({alertsCategoryFilter: selection})
    }

    onDownloadCSV = () => {
        const { AlertsStore,id,DeviceDetailStore } = this.props;
        const { data } = DeviceDetailStore;
        AlertsStore.loadDeviceAlertsCSV(id,data.hostname);
    }

    render() {
        const { DeviceDetailStore, AlertsStore, WindowsConfigStore } = this.props
        const { totalProcesses, randomizationByProcess, data, calculateCpu } = DeviceDetailStore

        const loadingStateAlerts = AlertsStore.loading;

        const arrayCpuProcessName: any = []
        const arrayCpuProcessPercentage: any = []
        const arrayCpuProcessEntropy: any = []

        const randProcessNames: any = []
        const randProcessPercentage: any = []
        const rand = randomizationByProcess.values.length;
        const total = totalProcesses;
        randProcessPercentage.push(rand);
        randProcessNames.push("Protected");
        randProcessPercentage.push(total - rand);
        randProcessNames.push("Not Protected");

        const {totalDeviceAlertsCount} = AlertsStore;

        calculateCpu.first_processes.forEach((item: any) => {
            arrayCpuProcessName.push(item.processName);
            arrayCpuProcessPercentage.push(item.cpuPercentage);
            arrayCpuProcessEntropy.push(item.entropyScore);
        })

        return (
            <div className="Endpoints">
                <h1>Endpoint: {data.ip_address} ({data.hostname}) </h1>
                <span className="ostype">OS Type: <span>{data.type === 'desktop' ? 'Windows' : 'Linux'}</span></span>
                <span className="vertical-line">|</span>
                <span className="ostype">Account: <span>{data.account_name}</span></span>
                <span className="vertical-line">|</span>
                <span className="heartbeat">Last Heartbeat: <span>{moment.utc(data.last_heartbeat).local().format('MMMM Do, YYYY, h:mm A')}</span></span>
                <span className="vertical-line">|</span>
                <span className="heartbeat">Agent Version: <span>{data.agent_version}</span></span>
                <span className="vertical-line">|</span>
                <span className="protection">Online Status: <span style={{marginLeft: 10}}>{data.entropy_score >= 2 ? <BsCheckLg color="green"/> : <BsXCircleFill color="red"/>} </span></span>
                <Container style={{marginLeft: 7}}>
                    { data.type === "desktop" &&
                        <p className="policy-assigned">
                            Memory Protection Inclusion List: |
                            <select
                                defaultValue={"Select a configuration"}
                                value={data.windows_device_configuration_id}
                                onChange={this.onAssignPolicy}
                            >
                                { data.windows_device_configuration_id != null ?
                                    <option disabled> -- select an option -- </option> :
                                    <option> -- select an option -- </option>}
                                {
                                    WindowsConfigStore.data.map(
                                        (WindowsConfig: any) => <option key={WindowsConfig.id}
                                                                        value={WindowsConfig.id}>{WindowsConfig.name}</option>
                                    )
                                }

                            </select>
                        </p>
                    }

                    <FormControlLabel
                        control={
                            <StyledSwitch
                                checked={data.ransomware_protection_restore_from_backup}
                                onChange={(event, checked) => this.onAssignRansomwareProtection(checked)}/>
                        }
                        label={MONITORING.RANSOMWARE_BACKUP}
                        labelPlacement='start'
                        style={{marginLeft: 0}}
                    />
                    
                </Container>

            <div className="row" style={{marginTop: 30, padding: '1rem'}}>
                <div className="col-lg-4 col-6">
                    <SmallBox
                        count={totalDeviceAlertsCount}
                        title="Alerts"
                        src={alertsIcon}
                        alt="alertsIcon"
                    />
                </div>
            </div>

                <div className='lower-div'>
                    <h4>Alerts</h4>

                    <button
                        type="button"
                        className="btn btn-primary new-user"
                        onClick={this.onDownloadCSV}
                        style={{ float: 'right', cursor: 'pointer', marginRight: '2rem' }}
                    >
                        <i className={'fas fa-plus-circle whiteicolor'} style={{ color: 'white', marginRight: 10 }}></i>
                        Download Alerts CSV
                    </button>

                    <ToggleGroupContainer>
                        <StyledToggleButtonGroup
                            value={this.state.alertsSeverityFilter}
                            handleOnChange={this.onSelectAlertsSeverity}
                            options={severitiesFilterOptions}
                        />
                    </ToggleGroupContainer>
                    { this.state.alertsSeverityFilter !== 'siem' &&
                        <ToggleGroupContainer>
                            <StyledToggleButtonGroup 
                                value={this.state.alertsCategoryFilter}
                                handleOnChange={this.onSelectAlertsCategory}
                                options={categoriesFilterOptions}
                            />
                        </ToggleGroupContainer>
                    }
                    

                    <EndpointAlertsTable data={AlertsStore.data.alerts} id={this.props.id}
                                         loadingState={loadingStateAlerts} showSearch={false}
                                         alertsSeverityFilter={this.state.alertsSeverityFilter}
                                         alertsCategoryFilter={this.state.alertsCategoryFilter}
                                         />

                </div>

            </div>
        );
    }
}

const EndpointMonitoringCmp = inject('DeviceDetailStore', 'WindowsConfigStore', 'AlertsStore')(observer(EndpointMonitoring));

const EndpointMonitoringWrapper = () => {
    const {id} = useParams();

    return <EndpointMonitoringCmp id={id}/>
}

const ToggleGroupContainer = styled('div')({
    marginBottom: 10
})

export default EndpointMonitoringWrapper;
