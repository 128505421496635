import React from 'react';
import { inject, observer } from 'mobx-react';

import Card from 'components/Card';
import ExclusionListsTable from './ExclusionListTable';
import EditExclusionListModal from './EditExclusionList';

import './ExclusionList.scss';
import { toastFail } from 'utils/Toast';

const SHOW_ALL_USERS = 'SHOW_ALL_USERS_CUSTOM_VALUE';

type ExclusionListsProps = {
    ExclusionListsStore?: any;
    UsersStore?:any;
    AuthStore?: any;
};

class ExclusionLists extends React.Component<ExclusionListsProps, any> {

    constructor(props: any) {
        super(props);
        this.state = {
            account: '',
            role: '',
            id: '',
        };
    }

    componentDidMount() {
        const { ExclusionListsStore, AuthStore, UsersStore } = this.props;
        ExclusionListsStore.loadData()
            .catch(() => {
                toastFail('Error loading exclusion list.');
            });
        if(this.state.id){ 
            ExclusionListsStore.loadDataById(this.state.id)
            .catch(() => {
                toastFail('Error loading exclusion list.');
            });
        }
            UsersStore.getAllAcounts();
        
        
        AuthStore.loadProfile()
            .catch((e: Error) => toastFail(e.message));

    }
    
    onAddEntry = () => {
        const { ExclusionListsStore } = this.props;
        ExclusionListsStore.setSelectedEntry("-1");
        ExclusionListsStore.creatingEntry = 1;
    }

    onAssignAccountId = (id:any) => {
        const { ExclusionListsStore } = this.props;
        this.setState({account: id})
        ExclusionListsStore.currentAccountID = id;
    }

    render() {
        const { ExclusionListsStore, AuthStore, UsersStore } = this.props;
        const { selectedEntry } = ExclusionListsStore;
        const { data } = ExclusionListsStore;
        const { accounts } = UsersStore;
        const loadingState = ExclusionListsStore.loading;

        if(AuthStore.isSupervisor){
            ExclusionListsStore.accounts?.items?.forEach((item:any) => {
                item['account_name'] = item.name
            });
        }

        return (
            <div className="Users">
                

                <h1>Exclusion List</h1>

                <button 
                    type="button" 
                    className="btn btn-primary new-user" 
                    onClick={this.onAddEntry}
                    style={{ float: 'right', cursor: 'pointer', marginRight: '2rem' }}
                >
                    <i className={'fas fa-plus-circle whiteicolor'} style={{ color: 'white', marginRight: 10 }}></i>
                    Add Entry
                </button>

                <span className="total-entries">Exclusion List Entries: {data.length}</span>
                <Card>
                    
                            <div className='account-selection'>
                                <label>Select an Account</label>
                                <select
                                    name="account"
                                    id="account"
                                    value={this.state.account}
                                    onChange={(e) => {
                                        this.onAssignAccountId(e.target.value)
                                        ExclusionListsStore.loadDataById(e.target.value)

                                    }}
                                    style={{ backgroundColor: "#343a40", color: 'white', border: 'none' }}
                                >
                                    <option disabled value=""> Select an Account </option>
                                    { accounts ? accounts.map((account:any) =>
                                         <option key={account.id} value={account.id}>{account.name}</option>
                                    )
                                    : 
                                    <p>No Options Available</p>}
                                </select>
                                <ExclusionListsTable 
                                    dataTable={ExclusionListsStore.data} 
                                    loadingState={loadingState} 
                                    noDataMessage={'Please select an account to see it\'s exclusion list entries.'} 
                                    showAccountName={this.state.account === SHOW_ALL_USERS ? true : false}
                                    />
                            </div>           
                </Card>

                {selectedEntry && <EditExclusionListModal />}
                
            </div>
        );
    }
}

export default inject('ExclusionListsStore','AuthStore','UsersStore')(observer(ExclusionLists));