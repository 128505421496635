import React, { useEffect, useState } from 'react';
import WindowsConfigsList from './WindowsConfigsList';
import AddPolicyModal from './AddPolicyModal';
import './AllowList.scss'
import PROPERTIES from 'properties';
import { ScreenContainer, TabTitle } from '../settings/Settings';
import { Button, Container } from '@mui/material';
import { TitleContainer } from '../home/Home';
import StyledSelect from 'components/StyledSelect';
import { useAccountsStoreContext } from 'stores/AccountsProvider';
import { useWindowsConfigsStoreContext } from 'stores/WindowsConfigsProvider';
import { useStoresContext } from 'stores/StoresProvider';

const { PROTECTED_LISTS } = PROPERTIES

export default function MemoryProtectionLists() {
    const [showAddPolicyModal, setShowAddPolicyModal] = useState<boolean>(false)

    const accountsStore = useAccountsStoreContext()
    const { accounts, selectedAccountIndex } = accountsStore

    const windowsConfigsStore = useWindowsConfigsStoreContext()
    const { authStore } = useStoresContext()

    useEffect(() => {
        accountsStore.loadAccounts()
    }, [])

    useEffect(() => {
        const selectedAccountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id
        const selectedAccountName = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].name
        windowsConfigsStore.loadData(selectedAccountId)
    }, [selectedAccountIndex])

    const options = accounts.map((account, index) => {
        return { value: index, label: account.name }
    })

    const withinVantage = accounts.find((account) => account.name === "Vantage exclusion list subaccount")

    const selectOptions = [{value: -1, label: 'All accounts'}, ...options]
    const selectedAccountId = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].id
    const selectedAccountName = selectedAccountIndex === -1 ? '' : accounts[selectedAccountIndex].name
    console.error(selectedAccountName)

    if(withinVantage){
        PROTECTED_LISTS.TITLE = "Inclusion/Exclusion Lists"
        if(selectedAccountName == ''){
            PROTECTED_LISTS.SUBHEADER = "Please use account selector on the right side of this page to select Vantage exclusion list subaccount to view excusion lists or Vantage Mobility to view memory protection lists. "
        }else if(selectedAccountName == 'Vantage exclusion list subaccount'){
            PROTECTED_LISTS.TITLE = "Exclusion list"
            PROTECTED_LISTS.SUBHEADER = "Set processes to be ignored by prevention"
        }else if(selectedAccountName == 'Vantage Mobility'){
            PROTECTED_LISTS.TITLE = "Memory Protection Inclusion Lists"
            PROTECTED_LISTS.SUBHEADER = "Set processes to be covered by memory protection"
        }else{
            PROTECTED_LISTS.SUBHEADER = "Set processes to be covered by memory protection"
        }
    }else{
        PROTECTED_LISTS.TITLE = "Memory Protection Inclusion Lists"
        PROTECTED_LISTS.SUBHEADER = "Set processes to be covered by memory protection"
    }

    // Set account id giving priority to the account selection
    let accountId = ''
    if (selectedAccountId !== '')
        accountId = selectedAccountId
    else if (authStore.user && authStore.user.account_id)
        accountId = authStore.user.account_id

    return (
        <ScreenContainer disableGutters style={{maxWidth: '-webkit-fill-available'}}>
            <Container disableGutters style={{marginLeft: 0, maxWidth: 'inherit'}}>
                <TitleContainer style={{marginRight: 0}}>
                    <TabTitle>{PROTECTED_LISTS.TITLE}</TabTitle>
                    <div style={{ display: 'flex'}}>
                        <Button
                            variant='contained'
                            style={{ cursor: 'pointer', marginRight: '2rem', height: '50px', alignSelf: 'center' }}
                            onClick={() => setShowAddPolicyModal(true)}
                        >
                            <i className={'fas fa-plus-circle whiteicolor'} style={{color: 'white', marginRight: 10}}></i>
                            New List
                        </Button>
                        <StyledSelect
                            label="Account"
                            handleOnChange={accountsStore.setSelectedAccountIndex}
                            options={selectOptions}
                            value={selectedAccountIndex}/>
                    </div>
                </TitleContainer>

                <span className="total-policies">{PROTECTED_LISTS.SUBHEADER}</span>

                <WindowsConfigsList accountId={accountId}/>

                { showAddPolicyModal && <AddPolicyModal onCancel={() => setShowAddPolicyModal(false)} accountId={accountId}/> }
            </Container>
        </ScreenContainer>
    )
}
