import { observable, action, computed, toJS, makeObservable } from 'mobx';
import { makeAuthenticatedRequest } from 'utils/API';
import { User,Account } from './AuthStore';

export class AccountsStore {
    public _loading: boolean;

    public selectedAccount?: Account;

    private _data: any[];
    private _accounts: any[];
    private _accountsById: any[];
    private _alerts: any[];

    private _users: any[];

    constructor() {
        makeObservable(this, {
            // @ts-ignore
            _data: observable,
            _accounts: observable,
            _accountsById: observable,
            _alerts: observable,
            _users: observable,
            _loading: observable,

            data: computed,
            loading: computed,
            accounts: computed,
            alerts: computed,
            accountsById: computed,
            users: computed,
            selectedAccount: observable,

            setSelectedAccount: action,
            clearSelectedAccount: action,
            onApiFailure: action,
            onLoadDataSuccess: action,
            onLoadAccountsByIdDataSuccess: action,
            onLoadAccountsDataSuccess: action, 
            loadData: action,
            loadDataById: action,
            getAllAcounts: action,
            getAllUsers: action,
            onApiFailureUserCreate:action,
            onLoadUsersDataSuccess: action,
            onLoadAllAlertsSuccess: action,
            create: action,
            update: action,
        });

        this._loading = false;
        this._data = [];
        this._accounts = [];
        this._accountsById = [];
        this._alerts = [];
        this._users = [];
        this.selectedAccount = undefined;
    }

    public get loading(): boolean {
        return toJS(this._loading);
    }

    public get data(): User[] {
        return toJS(this._data);
    }

    public get accounts(): any[] {
        return toJS(this._accounts);
    }

    public get accountsById(): any[] {
        return toJS(this._accountsById);
    }

    public get alerts(): any[] {
        return toJS(this._alerts);
    }

    public get users(): any[] {
        return toJS(this._users);
    }

    setSelectedAccount = (account: Account) => {
        this.selectedAccount = account;
    }

    clearSelectedAccount = () => {
        this.selectedAccount = undefined;
    }

    onApiFailure = (e: Error) => {
        this._loading = false;
        throw e;
    }

    onApiFailureUserCreate = (e: Error) => {
        const errorMessageJSON = JSON.parse(e.message);
        const errorMessageText = Object.values(errorMessageJSON)[0]

        this._loading = false;
        throw errorMessageText;
    }

    onLoadDataSuccess = ( response: Account[]) => {
        this._data = response;
        this._loading = false;
    }

    onLoadAccountsDataSuccess = ( response: Account[]) => {
        this._accounts = response;
        this._loading = false;
    }

    onLoadUsersDataSuccess = ( response: any) => {
        this._users = response;
        this._loading = false;
    }

    onLoadAccountsByIdDataSuccess = ( response: any) => {
        this._accountsById = response;
        this._loading = false;
    }

    onLoadAllAlertsSuccess = ( response: any) => {
        this._alerts = response;
        this._loading = false;
    }

    loadData = (): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts`,
            options: { method: 'GET' }
        })
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);
    }

    loadDataById = (id: any): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${id}/users`,
            options: { method: 'GET' }
        })
            .then(this.onLoadAccountsByIdDataSuccess)
            .catch(this.onApiFailure);
    }

    create = (data: Account): Promise<any> => {
        this._loading = true;
        return makeAuthenticatedRequest({
            url: `/api/v2/accounts`,
            options: { method: 'POST' },
            data
        })
            .then(this.loadData)
            .catch(this.onApiFailureUserCreate);
    }

    update = (account: Account): Promise<any> => {
        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${account.id}`,
            options: { method: 'PUT' },
            data: account
        })
        .then(() => this.updateAccounts(account))
        .catch(this.onApiFailure);
    }
    update_modes = (account: Account): Promise<any> => {
        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${account.id}`,
            options: { method: 'PATCH' },
            data: account
        })
        .then(() => this.updateAccounts(account))
        .catch(this.onApiFailure);
    }

    getAllAcounts = (): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts`,
            options: { method: 'GET' },
        })
            .then(this.onLoadAccountsDataSuccess)
            .catch(this.onApiFailure)
    }

    getAllUsers = (): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/users`,
            options: { method: 'GET' },
        })
            .then(this.onLoadUsersDataSuccess)
            .catch(this.onApiFailure)
    }

    updateAccounts(newAccount: Account) {
        this._accounts.map((oldAccount: Account) => {
            if (oldAccount.id !== newAccount.id)
                return oldAccount
            else
                return newAccount
        })
    }
}

const STORE = new AccountsStore();

export default STORE;