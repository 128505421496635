import React, { useEffect, useState } from 'react';
import JSZip from "jszip";
import ReactMarkdown from 'react-markdown';
import { Tabs, Tab } from 'react-bootstrap';
import { toastFail,toastSuccess } from 'utils/Toast';
import './Setup.scss';
import PROPERTIES from 'properties';
import { useStoresContext } from 'stores/StoresProvider';
import { UsersResponse } from 'stores/UsersStore';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import StyledDialog from 'components/StyledDialog';
import { useAccountsStoreContext } from 'stores/AccountsProvider';

const { VENDOR_INSTALL } = PROPERTIES
const faqmarkdown = `The following is a list of common info and questions related to usage
1) **During install, I get a prompt stating processes associated with the install require a reboot?**   
    This message can occur occasionally, simply press okay on the prompt and the installation will continue.
    
    
2) **What programs are being protected?**   
    The list of programs that are to be protected are generated at install time 
    dynamically. If you want to see this list, you can find it by opening up event 
    viewer from the start menu. Open event viewer, expand Applications and Services Logs, 
    select WinRandInitialization. The latest log will show WinRand Install: enable 
    function origin check and function honeypots. The second to latest log will 
    show WinRand Install: enable function origin check.
   
    
3) **What attacks/behavior does this mitigate?**   
    This solution is considered to be a form of Runtime Application Self Protection (RASP), 
    which dynamically adjusts the runtime environment, injects hook protections and monitors
    into an underlying process, and can automatically mitigate exploits in progress. Specifically,
    existing protections can help mitigate entry level and persistentance techniques requiring 
    memory corruption behavior (e.g. ROP, buffer overflows). Work is underway to extend this protection
    to include enforcements that assure memory code execution integrity, preventing critical memory 
    manipulation requirements in the modern kill chain (e.g. antivirus evasion, DLL injection,
    runtime function patching/hooking, privilege escalation). WinRand helps to address software/system
    integrity, malicious software prevention/detection, and monitoring controls within many common 
    industry frameworks (e.g. NIST 800-53, NIST 800-171, CMMC 2.0, PCI, HIPPAA etc).
   
    
4) **What are the protections?**   
    Honeypots refer to moving sensitive functions to random locations (load-time process memory 
    randomization (Windows)/continuous process memory randomization (Linux/Kubernetes)) plus leaving 
    a trap hook in the old location. Function origin check refers to runtime control flow 
    checks for sensitive functions, such as if the function invocation originated from a ret statement.
    These protection are designed to mitigate and detect the end stages of memory corruption 
    (e.g. rop/buffer overflow) behavior that attempts to interact with the system. Sensitive functions 
    refer to common functions used to access the system such as CreateProcess.
       

5) **How can I see if Winrand honeypots are protecting my program?**    
    When Winrand finishes the relocation of sensitive functions and placement
    of traps, it then produces a log message informing successful initialization.
    you can find these log messages by opening up event viewer from the start
    menu. Open event viewer, expand Applications and Services Logs, select
    select WinRandDiagnostics.
    

6) **Why am I not seeing data on the dashboard?**    
    This could be due to a misconfiguration. Navigate to   
    C:\\Program Files (x86)\\ArmsCyber\\Settings   
    and open LocalConfig.txt. Ensure the contents follow the following template:   
       
    http://api.armscyber.com    
    {APIKEY}  
    apikey  
    {USER_ID}  
       
    Get the values for {APIKEY} and {USER_ID} from 
    https://phalanx.armscyber.com/profile . After any change to the LocalConfig.txt
    file, you will have to restart the data collection service. go to services, 
    navigate to WinrandEventCollection and right click on the service, then 
    select restart.
    `

const windowsvendormarkdown = `
## Arms Endpoint
- Download ArmsEndpoint.zip by selecting an account for the installer, and then clicking on the vendor installer button
- Extract ArmsEndpoint.zip
- To install silently using your deployment tool, run "./ArmsEndpoint.exe /q /norestart"
- To uninstall silently using your deployment tool, run "./ArmsEndpoint.exe /x // /q"
`

const windowsselfmarkdown = `The following guide goes over how to install winrand on an individual machine
## Prereqs
- Download and install prereqs [.NET Framework 4.0](https://storage.googleapis.com/winrand-msi/self_install/1.2/prereqs/dotNetFx40_Full_setup.exe)


## Winrand
- Download and install winrand msi [ArmsCyberWinrandInstaller](https://storage.googleapis.com/winrand-msi/self_install/1.3/desktop/Winrand.msi)
- During install, you will be prompted for whether or not you are using the test or prod environment. If the link to the dashboard you
  are using is https://test.armscyber.com/, select test. If the link to the dashboard you are using is https://phalanx.armscyber.com/,
  select prod.
- Then, you will be prompted for your credentials
- After install, check the dashboard to make sure the endpoint has been registered

`

const betawindowsselfmarkdown = `The following guide goes how to install winrand on an individual machine
## Prereqs
- Download and install prereqs [.NET Framework 4.0](https://storage.googleapis.com/winrand-msi/self_install/1.2/prereqs/dotNetFx40_Full_setup.exe)


## Winrand
- Download and install winrand msi [ArmsCyberWinrandInstaller](https://storage.googleapis.com/winrand-msi/beta/1.2/desktop/Winrand.msi)
- During install, you will be prompted for whether or not you are using the test or prod environment. If the link to the dashboard you
  are using is https://test.armscyber.com/, select test. If the link to the dashboard you are using is https://phalanx.armscyber.com/,
  select prod.
- Then, you will be prompted for your credentials
- After install, check the dashboard to make sure the endpoint has been registered

`

const dashboardmarkdown = `The following guide outlines several components of the dashboard.
## Dashboard Overview
The ARMS Phalanx dashboard has four main tabs on the left side navigation bar
1) **Dashboard** - Illustrates high level metrics that show collective statistics about the endpoint set
2) **Endpoints** - Illustrates the set of active endpoints for the user to monitor
     * **Endpoint Monitoring Page** - Users are able to select an endpoint to view detailed information about the state of operation, security threats, and protective measures
3) **Policies** - Allows users to view, create, and edit security policies that dictate behavior on proected endoints
4) **Users** - Admins are allowed to create, and manage subsidiary user accounts for their organization

### Dashboard Page
Starting from the top and from left to right, the following components appear on the dashboard page
1) **Top Header**
     - **Total Endpoints Connected** - The total number of devices being monitored
     - **Protected** - Number of devices with active randomization protection
     - **Unprotected** - Number of devices with inactive randomization protection
2) **First Row**
     - **Total Endpoints** - Number of current devices being monitored
     - **Total Processes** - Number of current longterm processes (longer then 20 second lifetime) being protected/monitored 
     - **Total Alerts** - Total number of alerts received
     - **Overall Risk** - Level reflecting magnitude of threat space not protected by randomization. This risk is reflected by a color code
         - Very Low/Low (Green) - Majority of devices and processes are protected by randomization
         - Medium (Yellow) - Average number of devices and processes are protected by randomization
         - Very High/High (Red) - Low number of devices and processes are protected by randomization
3) **Second Row**
     - **Alerts Bar Chart**  - Shows the monthly alerts from the last 12 months. Trends can be identified indicating potential increases and decreases in adversary activity
4) **Third Row**
     - **Top Alerted Endpoint** - Top 5 endpoints by number of alerts
     - **Top Alert Type** - Breakdown of alerts by category
     - **Top Protected Processes** - Top 5 processes by occurence
5) **Fourth Row**
     - **Alerts table** - Table of alerts/threats received. Each alert has the following fields
         - IP Address
         - Hostname
         - Endpoint type - Server, container, or desktop
         - OS Type
         - Timestamp
         - Alert type
### Endpoints Page
Starting from the top down, the following components appear on the endpoints list page
1) **Servers Table** - Lists the server devices currently connected to the dashboard
2) **Desktops Table** - Lists the desktop devices currently connected to the dashboard
3) **Container Pods Table** - Lists the container pods currently connected to the dashboard

Each item in these tables includes the following fields
- IP Address - **Clicking this on any endpoint item will link to the respective monitoring page**
- Hostname
- OS Type
- Uptime
- Protection Status
- Last Process Heartbeat
- Level of protection - Very low, low, medium, high, or very high depending on level and use of randomization
### Endpoint Monitoring Page
Starting from the top and from left to right, the following components appear on the endpont monitoring page. As a reminder you can get to this page by clicking the IP address on a row on the endpoints page
1) **Top Header**
     - **OS Type** - The operating system of the endpoint
     - **Last Heartbeat** - The timestamp of the last received process heartbeat on the endpoint
     - **Protection Status** - Is randomization enabled on the endpoint
     - **Policy Assigned** - User can choose a respective randomization time period policy to assign to the endpoint
2) **First Row**
     - **CPU Usage Chart/Protection Percentage Chart (Windows)** - Breaks down the system CPU usage by process/Pecentage of process protected on Windows
     - **Alerts** - Total number of alerts associated with the endpoint
     - **Randomized** - Number of current processes with randomization proection enabled on the endpoint
     - **Total Processes** - Total number of current monitored processes on the endpoint
     - **Process Entropy Chart** - Illustrates entropy level for each process (4=highest protection, 0=no protection)
3) **Second Row**
     - **Alerts table** - Table of alerts/threats received associated with the endpoint. Each alert has the following fields
         - IP Address
         - Hostname
         - Endpoint type - Server, container, or desktop
         - OS Type
         - Timestamp
         - Alert type
3) **Third Row**
     - **Processes table** - Table of chrrent processesassociated with the endpoint.
### Policies Page
The following components appear on the policies page
1) **New Policy Button** - Brings up a form for user to create a new policy. The form has the following fields
     - **Policy Name** - This will be referenced by endpoints to determine target policy data
     - **Randomization Interval** - The target period for randomization events in seconds
2) **Policies Card View** - Shows the policies in an organized card view. Each policy card has the following subcomponents
     - **Name** - The name of the policy (This is what is referenced from endpoint associations)
     - **Frequency** - The policy target randomization interval in seconds
     - **Deletion icon** - Deletes the policy
### Users Page
The following components appear on the users page
1) **New User Button** - Brings up a form for user to create a new user. The form has the following fields
     - First Name
     - Last Name
     - Email Address
     - Account - Oranization Account
     - Role - Specify admin privileges or regular user
     - Password - Starting password (User can reset later through reset password page on login screen)
2) **Users Table** - Shows the users in a table list. Each user element has the following capabilities included in the right two columns.
     - **Edit** - The pencil icon
     - **Delete** - The trash can icon
`

const linuxmarkdown = `The following guide illustrates how to successfully install, configure, and run the ARMS Phalanx agent on a Linux variant endpoint.
## Client Instructions
1) Install libRERAND agent

    \`\`\`
    curl -s https://packagecloud.io/install/repositories/ACD/librerand/script.deb.sh | bash
    apt-get install librerand
    \`\`\`

2) Verify librerand installed successfully
    \`\`\`
    # Should show dependency on libRerand.so
    ldd /usr/lib/x86_64-linux-gnu/libRerand*
    \`\`\`

3) Update phalanx configuration file at \`/etc/phalanx_agent/.phalanx_conf\`. Insert account token from dashboard.
    \`\`\`
    #/etc/phalanx_agent/.phalanx_conf file

    RAND_PERIOD=[randomization period (set to 10 seconds by default)]
    SERVER_URL=[server URL (set by default)]
    API_TOKEN=[insert account token from dashboard profile page]
    \`\`\`
4) Run phalanx agent
    \`\`\`
    librerand-agent &
    \`\`\`
5) Configure environmental variables (either through terminal or \`.bashrc\` file)
    - RERAND - 2 \`(0 = no randomization, 1 = load time randomization, 2 = continuous randomization)\`
    - LD_BIND_NOW - 1
    - LD_PRELOAD - \`/usr/lib/x86_64-linux-gnu/libRerand.so\`
    \`\`\`
    export RERAND=2; 
    export LD_BIND_NOW=1;
    export LD_PRELOAD=/usr/lib/x86_64-linux-gnu/libRerand.so;
    \`\`\`
6) Run your target application like normal (example Redis Server)
    \`\`\`
    # Example target application is redis server
    redis-server &
    \`\`\`
    
`

const Kubernetesmarkdown = `The following guide illustrates how to successfully install, configure, and run the ARMS Phalanx agent on a Kubernetes Pod.
## Pod Instructions
1) Setup Init Container configuration
    1) Integrate ARMS docker sidecar init image \`(armscyberdefense/rerand-image)\`
    2) Create a volume and working directory at *work-dir* location (Volume named workdir)
    3) Create a volume named logs (This will be used for sidecar monitoring step)
    4) Copy ARMS randomization libraries to work-dir directory

    \`\`\`
    # This container is run during pod initialization to integrate Phalanx libraries 
      into the main pod application container

    initContainers:
    - name: phalanx-init
        image: armscyberdefense/rerand-image
        command: ["/bin/sh"]
        args: ["-c", "cp -R /rerand /work-dir/rerand"]
        volumeMounts:
        - name: workdir
        mountPath: "/work-dir"
    dnsPolicy: Default
    volumes:
    - name: workdir
        emptyDir: {}
    - name: logs
        emptyDir: {}
    \`\`\`
2) Setup Application Container
    1) Setup application container configuration like normal (We use armscyberdefense/integ_bionic:1.1 as an example)
    2) Mount workdir volume at \`/home\` directory in application container
    3) Mount logs volume at \`/tmp/Logs\` directory in application container
    4) Configure the following environmental variables
        - LD_LIBRARY_PATH - /home/rerand
        - RERAND - 2 \`(0 = no randomization, 1 = load time randomization, 2 = continuous randomization)\`
        - LD_BIND_NOW - 1
        - LD_PRELOAD - \`/home/rerand/libRerand.so\`
    \`\`\`
    # This is the setup for your main pod application cotainer
    # Command sleep 1000 is used as an example container command

    containers:
    - name: beta
        image: armscyberdefense/integ_bionic:1.1
        env:
        - name: LD_LIBRARY_PATH
        value: /home/rerand/
        - name: RERAND
        value: "2"
        - name: LD_BIND_NOW
        value: "1"
        - name: LD_PRELOAD
        value: /home/rerand/libRerand.so
        command: ["/bin/sh"]
        args: ["-c", "sleep 1000"]
        volumeMounts:
        - name: workdir
        mountPath: /home
        - name: logs
        mountPath: /tmp/Logs
    \`\`\`

    3) Setup Sidecar Monitoring Container
        1) Integrate ARMS docker sidecar monitoring image \`(armscyberdefense/sidecar-agent)\`
        2) Mount logs volume at \`/tmp/Logs\` directory in sidecar container
        3) Configure the following environmental variables
            - RAND_PERIOD - Time in seconds, default is 10 seconds
            - SERVER_URL - API server URL location. Leave blank unless using on-prem configuration.
            - API Token - Authentication token for API, can be found on profile page in dashboard.
    \`\`\`
    # This is the setup for the sidecar monitoring container

    containers:
    ... [declaration of main application container from step 2] ...
    
    - name: phalanx-sidecar
        image: armscyberdefense/sidecar-agent
        env:
        - name: RAND_PERIOD
        value: [Time in seconds, default is 10 seconds]
        - name: SERVER_URL
        value: [Leave blank unless using on-prem configuration]
        - name: API TOKEN
        value: [API Token from Dashboard]
        volumeMounts:
        - name: logs
        mountPath: /tmp/Logs
    \`\`\`
    
`

const licenseAgreementMarkdown = `
##### **IMPORTANT - READ BEFORE INSTALLING OR USING**

&ZeroWidthSpace;

**Please do not install or use this software until you have carefully read the following
terms and conditions. By using our software, you agree to the terms of this
Agreement.**

&ZeroWidthSpace;

1. **DEFINITIONS.**

    - **(a)** &quot;Software&quot; means the Arms Cyber endpoint agent, to include all past and
subsequent versions.

    - **(b)** &quot;Install&quot; means placing the Software on a computer&#39;s hard disk, CD-ROM or other
secondary storage device.

    - **(c)** &quot;Use&quot; means (i) executing or loading the Software into computer RAM or other
primary memory, and (ii) copying the Software for archival or emergency restart
purposes.
 
2. **TERMINATION.** Licensor shall have the right to immediately terminate this License if
Licensee fails to perform any obligation required of Licensee under this License or if
Licensee becomes bankrupt or insolvent.

3. **RETURN OR DESTRUCTION OF SOFTWARE UPON TERMINATION.** Upon termination
of this License, Licensee shall return to Licensor or destroy the original and all copies of
the Software including partial copies and modifications. Licensor shall have a reasonable
opportunity to conduct an inspection of Licensee&#39;s place of business to assure
compliance with this provision.

4. **TITLE TO SOFTWARE.** Licensor retains title to and ownership of the Software, along
with all enhancements, modifications and updates.

5. **MODIFICATIONS AND ENHANCEMENTS.** Licensee will make no efforts to reverse
engineer the Software, or make any modifications or enhancements without Licensor&#39;s
express written consent.

6. **WARRANTY LIMITATIONS.** THE SOFTWARE IS PROVIDED &quot;AS IS.&quot; LICENSOR
DISCLAIMS ALL WARRANTIES, INCLUDING BUT NOT LIMITED TO, ALL EXPRESS OR
IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR
PURPOSE.

    SOME STATES DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE
ABOVE EXCLUSION MAY NOT APPLY TO YOU.

7. **REMEDY LIMITATIONS.** Licensor&#39;s entire liability and Licensor&#39;s sole and exclusive
remedy for breach of the foregoing warranty shall be Licensor&#39;s option to either:

    - **(a)** return to Licensee the license fee for the period in which the Software did not
perform according to this warranty, or

    - **(b)** repair the defects or replace the Software.

8. **DAMAGE LIMITATIONS.** NEITHER PARTY SHALL BE LIABLE TO THE OTHER FOR
INDIRECT, SPECIAL, CONSEQUENTIAL OR INCIDENTAL DAMAGES, INCLUDING LOSS OF
PROFITS, AND LICENSOR&#39;S LIABILITY TO LICENSEE FOR ANY OTHER DAMAGES RELATING
TO OR ARISING OUT OF THIS AGREEMENT WHETHER IN CONTRACT, TORT, OR
OTHERWISE WILL BE LIMITED TO THE AMOUNT RECEIVED BY LICENSOR FROM LICENSEE
AS COMPENSATION FOR THE SOFTWARE DURING THE 0 MONTH PERIOD IMMEDIATELY
PRIOR TO THE TIME SUCH CLAIM AROSE.

9. **CONFIDENTIALITY.** Licensee will treat the Software as a trade secret and proprietary
know-how belonging to Licensor that is being made available to Licensee is made in
confidence. Licensee agrees to treat the Software with at least the same care as it treats
its own confidential or proprietary information.

10. **ARBITRATION.** The parties agree to submit any dispute under this License to binding
arbitration under the rules of the American Arbitration Association in Howard County,
Maryland. Judgement upon the award rendered by the arbitrator may be entered in any
court with jurisdiction to do so.

11. **ATTORNEY FEES.** If any legal action is necessary to enforce this License, the
prevailing party shall be entitled to reasonable attorney fees, costs and expenses in
addition to any other relief to which it may be entitled.

12. **GENERAL PROVISIONS.**

    - **(a)** Complete Agreement: This License Agreement together with all schedules
referred to in this Agreement, all of which are incorporated herein by reference,
constitutes the sole and entire Agreement between the parties. This Agreement
supersedes all prior understandings, agreements, representations and documentation
relating to the subject matter of this Agreement.

    - **(b)** Modifications: Modifications and amendments to this Agreement, including
any exhibit, schedule or attachment hereto, shall be enforceable only if in writing and
signed by authorized representatives of both parties.

    - **(c)** Applicable Law: This License will be governed by the laws of the State of
Maryland

    - **(d)** Notices: All notices and other communications given in connection with this
License shall be in writing and shall be deemed given as follows:

        - When delivered personally to the recipient&#39;s address as appearing in the
introductory paragraph to this License;

        - Three days after being deposited in the United States mail, postage prepaid to
the recipient&#39;s address as appearing in the introductory paragraph to this
License; or

        - When sent by fax or telex to the last fax or telex number of the recipient
known to the party giving notice. Notice is effective upon receipt provided that a
duplicate copy of the notice is promptly given by first-class or certified mail or
the recipient delivers a written confirmation of receipt.

        - Any party may change its address appearing in the introductory paragraph to
this License by given notice of the change in accordance with this paragraph

        - No Agency: contained herein will be construed as creating any agency,
partnership, joint venture or other form of joint enterprise between the parties

13. **ASSIGNMENT.** The rights conferred by this License shall not be assignable by the
Licensee without Licensor&#39;s prior written consent. Licensor may impose a reasonable
license fee on any such assignment.
`

function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export default function Setup() {
    const [account, setAccount] = useState<string>('')
    const [openAgreement, setOpenAgreement] = useState<boolean>(false)
    const [onClickAgree, setOnClickAgree] = useState<() => void>(() => {})
    const [readAgreement, setReadAgreement] = useState<boolean>(false)

    const accountsStore = useAccountsStoreContext()
    const stores = useStoresContext()

    const { authStore: AuthStore, usersStore: UsersStore} = stores
    const { accounts } = accountsStore

    useEffect(() => {
        AuthStore.loadProfile().catch((e: Error) => toastFail(e.message))
        AuthStore.loadAPIKey().catch((e: Error) => toastFail(e.message))
        AuthStore.loadMyAccount()
        accountsStore.loadAccounts()
    }, [])

    function onClickInstall(callback: () => void) {
        return (event: React.MouseEvent<unknown>) => {
            setOpenAgreement(true)
            setOnClickAgree(() => () => {
                callback()
                onCloseAgreement()  
            })
        }
    }

    function onCloseAgreement() {
        setOpenAgreement(false)
        setReadAgreement(false)
    }

    function onClickReadAgreement(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        setReadAgreement(checked)
    }

    function onDownloadURI() {
        if(account !== ''){
            var download_string = "https://storage.googleapis.com/winrand-msi/self_install/Current/ArmsEndpoint.exe";
            var targetAccountId = account.substring(0, 36)
            var targetAccountName = account.substring(37)
            var serviceUserEmail = "su@" + targetAccountId + ".com"
            var myuuid = makeid(40);
            var serviceUser = {first_name: "service",last_name: "user",email: serviceUserEmail,role:"admin",password: myuuid,account_id: targetAccountId}
            var createdServiceUser = AuthStore.createServiceUser(serviceUser)
            createdServiceUser.then(() => {
                var returnedUsers = UsersStore.loadDataById(targetAccountId)
                returnedUsers.then(() => {
                    var targetAccountUsers = UsersStore.accountsById as UsersResponse
                    var serviceUserId = ""
                    for (let i=0; i<targetAccountUsers.items.length; i++){
                        if(targetAccountUsers.items[i].first_name === "service"){
                            if(targetAccountUsers.items[i].last_name === "user"){
                                serviceUserId = targetAccountUsers.items[i].id
                            }
                        }
                    }
                    
                    if(serviceUserId === ""){
                        var message = "Couldn't get a service user in account " + targetAccountName
                        toastFail(message)
                    }
                    else {
                        var createAPIKey = AuthStore.createUserAPIToken(serviceUserId)
                        createAPIKey.then(() => {
                            var getAPIKey = AuthStore.loadUserAPIKey(serviceUserId)
                            getAPIKey.then(() => {
                                fetch(download_string, {mode: 'cors'}).then((response) => response.blob())
                                .then((blobresp) => {
                                    if(AuthStore.serviceapikey === ""){
                                        var message = "Couldn't get api key of service user in account " + targetAccountName
                                        toastFail(message)
                                    }
                                    else{
                                        toastSuccess("Preparing vendor installer");
                                        var serviceapikey = AuthStore.serviceapikey
                                        var blob = new Blob([blobresp], {type: "octet/stream"});
                                        var mytext = Promise.resolve(blob.arrayBuffer())
                                        mytext.then((value) => {
                                            var zip = new JSZip();
                                            var settings_string = "https://api.armscyber.com\n" + serviceapikey + "\napikey\n" + serviceUserId + "\n" + targetAccountId
                                            zip.file("LocalConfig.txt", settings_string);
                                            zip.file("ArmsEndpoint.exe", value, {base64: true});
                                            var filename = "ArmsEndpoint_for_" + targetAccountName + ".zip"
                                            zip.generateAsync({type:"blob"})
                                            .then(function(content) {
                                                // Force down of the Zip file
                                                var url = window.URL.createObjectURL(content);
                                                var link = document.createElement("a");
                                                link.download = filename;
                                                link.href = url;
                                                document.body.appendChild(link);
                                                link.click();
                                                document.body.removeChild(link);
                                            });
                                            
                                        })
                                    }
                                })
                            })
                        })
                    }
                    
                    
                })
            })
            .catch(console.error);
        }
        else{
            toastFail("Please select an account")
        }
    }

    function onDownloadVer1_7_4() {
        toastSuccess("Preparing vendor installer");
        var download_string = "https://storage.googleapis.com/winrand-msi/vendor_install/Vantage/1.07.004/Winrand.exe";
        fetch(download_string, {mode: 'cors'}).then((response) => response.blob())
        .then((blobresp) => {
            var blob = new Blob([blobresp], {type: "octet/stream"});
            var mytext = Promise.resolve(blob.arrayBuffer())
            mytext.then((value) => {
                var zip = new JSZip();
                zip.file("Winrand.exe", value, {base64: true});
                zip.generateAsync({type:"blob"})
                .then(function(content) {
                    // Force down of the Zip file
                    var url = window.URL.createObjectURL(content);
                    var link = document.createElement("a");
                    link.download = "Winrand.zip";
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });

            })
        })
        .catch(console.error);
    }

    function onDownloadVer1_7_5() {
        toastSuccess("Preparing vendor installer");
        var download_string = "https://storage.googleapis.com/winrand-msi/vendor_install/Vantage/1.07.005/Winrand.exe";
        fetch(download_string, {mode: 'cors'}).then((response) => response.blob())
        .then((blobresp) => {
            var blob = new Blob([blobresp], {type: "octet/stream"});
            var mytext = Promise.resolve(blob.arrayBuffer())
            mytext.then((value) => {
                var zip = new JSZip();
                zip.file("Winrand.exe", value, {base64: true});
                zip.generateAsync({type:"blob"})
                .then(function(content) {
                    // Force down of the Zip file
                    var url = window.URL.createObjectURL(content);
                    var link = document.createElement("a");
                    link.download = "Winrand.zip";
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });

            })
        })
        .catch(console.error);
    }
    
    function onDownloadVer1_8_1() {
        toastSuccess("Preparing vendor installer");
        var download_string = "https://storage.googleapis.com/winrand-msi/vendor_install/Vantage/1.08.001/Winrand.exe";
        fetch(download_string, {mode: 'cors'}).then((response) => response.blob())
        .then((blobresp) => {      
            var blob = new Blob([blobresp], {type: "octet/stream"});
            var mytext = Promise.resolve(blob.arrayBuffer())
            mytext.then((value) => {
                var zip = new JSZip();
                zip.file("Winrand.exe", value, {base64: true});
                zip.generateAsync({type:"blob"})
                .then(function(content) {
                    // Force down of the Zip file
                    var url = window.URL.createObjectURL(content);
                    var link = document.createElement("a");
                    link.download = "Winrand.zip";
                    link.href = url;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });

            })
        })
        .catch(console.error);
    }

    return (
        <div className="Setup">
            <h1 className="title-endpoints">{VENDOR_INSTALL.TITLE}</h1>
            <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                className="mb-3"
            >
                <Tab eventKey="home" title={VENDOR_INSTALL.WINDOWS_DESKTOPS.TITLE}>
                    <br></br>
                    {AuthStore.accountName === "Vantage Mobility" ? null :<label style={{ float: 'right', cursor: 'pointer', marginRight: '2rem' }}>Select an Account for the vendor installer</label>}
                    <br></br>
                    {AuthStore.accountName === "Vantage Mobility" ? null :
                        <select
                            name="account"
                            id="account"
                            value={account}
                            onChange={(e) => {
                                setAccount(e.target.value)
                                UsersStore.loadDataById(e.target.value)
                            }}
                            style={{ float: 'right', cursor: 'pointer', marginRight: '2rem' , backgroundColor: "#343a40", color: 'white', border: 'none' }}
                        >
                            <option value=""> Select an Account </option>
                            { accounts ? accounts.map((account:any) =>
                                <option key={account.id} value={[account.id,account.name]}>{account.name}</option>
                                )
                                : 
                                <p>No Options Available</p>
                            }
                        </select>
                    }
                    <br></br>

                    {AuthStore.accountName === "Vantage Mobility" ? null : 
                        <button 
                            type="button" 
                            className="btn btn-primary new-user" 
                            onClick={onClickInstall(onDownloadURI)}
                            style={{ float: 'right', cursor: 'pointer', marginRight: '2rem' }}
                        >
                            <i className={'fas fa-plus-circle whiteicolor'} style={{ color: 'white', marginRight: 10 }}></i>
                            Vendor Installer
                        </button>
                    }
                    <ReactMarkdown children={windowsvendormarkdown} />
                    {AuthStore.accountName === "Vantage Mobility" ? <h3>Versions</h3> : null }
                    {AuthStore.accountName === "Vantage Mobility" ? <ul><li>Version 1.07.004 : Feature update - includes a minimal sysmon config to limit sysmon intrusiveness</li><li>Version 1.07.005 : Fix - restricts sending up duplicate alerts</li><li>Version 1.08.001 : Feature update - includes a balanced sysmon config (omits detection of registry changes)</li></ul> : null }
                    {AuthStore.accountName !== "Vantage Mobility" ? null :
                        <button 
                            type="button" 
                            className="btn btn-primary new-user" 
                            onClick={onClickInstall(onDownloadVer1_7_4)}
                            style={{ float: 'right', cursor: 'pointer', marginRight: '2rem' }}
                        >
                            <i className={'fas fa-plus-circle whiteicolor'} style={{ color: 'white', marginRight: 10 }}></i>
                            Version 1.07.004
                        </button>
                    }
                    {AuthStore.accountName !== "Vantage Mobility" ? null :
                        <button 
                            type="button" 
                            className="btn btn-primary new-user" 
                            onClick={onClickInstall(onDownloadVer1_7_5)}
                            style={{ float: 'right', cursor: 'pointer', marginRight: '2rem' }}
                        >
                            <i className={'fas fa-plus-circle whiteicolor'} style={{ color: 'white', marginRight: 10 }}></i>
                            Version 1.07.005
                        </button>
                    }
                    {AuthStore.accountName !== "Vantage Mobility" ? null :
                        <button 
                            type="button" 
                            className="btn btn-primary new-user" 
                            onClick={onClickInstall(onDownloadVer1_8_1)}
                            style={{ float: 'right', cursor: 'pointer', marginRight: '2rem' }}
                        >
                            <i className={'fas fa-plus-circle whiteicolor'} style={{ color: 'white', marginRight: 10 }}></i>
                            Version 1.08.001
                        </button>
                    }   
                </Tab>
            </Tabs>

            <StyledDialog
                open={openAgreement}
                onClose={onCloseAgreement}
                title='ARMS CYBER SOFTWARE LICENSE AGREEMENT'
                content={
                    <>
                        <ReactMarkdown children={licenseAgreementMarkdown} />
                        <FormGroup>
                            <FormControlLabel control={<Checkbox checked={readAgreement} onChange={onClickReadAgreement}/>} label={'I have read and accept the terms and conditions'}></FormControlLabel>
                        </FormGroup>
                    </>
                }
                disablePrimary={!readAgreement}
                onClickPrimary={onClickAgree}
                primaryText='Download'/>
        </div>
    )
}
