import { observable, action, computed, toJS, makeObservable } from 'mobx';
import { makeAuthenticatedRequest } from 'utils/API';
import AuthStore, { User } from './AuthStore';
import { Metadata } from './AlertsStore';

export type UsersResponse = {
    items: User[],
    total: number,
    metadata: Metadata
}

export class UsersStore {
    public _loading: boolean;

    public selectedUser?: User;

    private _data: any[];
    private _accounts: any[];
    private _accountsById: any[];
    private _alerts: any[];

    private _users: any[];

    constructor() {
        makeObservable(this, {
            // @ts-ignore
            _data: observable,
            _accounts: observable,
            _accountsById: observable,
            _alerts: observable,
            _users: observable,
            _loading: observable,

            data: computed,
            loading: computed,
            accounts: computed,
            alerts: computed,
            accountsById: computed,
            users: computed,
            selectedUser: observable,

            setSelectedUser: action,
            clearSelectedUser: action,
            onApiFailure: action,
            onLoadDataSuccess: action,
            onLoadAccountsByIdDataSuccess: action,
            onLoadAccountsDataSuccess: action, 
            loadData: action,
            loadDataById: action,
            getAllAcounts: action,
            getAllUsers: action,
            onApiFailureUserCreate:action,
            onLoadUsersDataSuccess: action,
            onLoadAllAlertsSuccess: action,
            create: action,
            update: action,
            delete: action,
        });

        this._loading = false;
        this._data = [];
        this._accounts = [];
        this._accountsById = [];
        this._alerts = [];
        this._users = [];
        this.selectedUser = undefined;
    }

    public get loading(): boolean {
        return toJS(this._loading);
    }

    public get data(): User[] {
        return toJS(this._data);
    }

    public get accounts(): any[] {
        return toJS(this._accounts);
    }

    public get accountsById(): any {
        return toJS(this._accountsById);
    }

    public get alerts(): any[] {
        return toJS(this._alerts);
    }

    public get users(): any[] {
        return toJS(this._users);
    }

    setSelectedUser = (user: User) => {
        this.selectedUser = user;
    }

    clearSelectedUser = () => {
        this.selectedUser = undefined;
    }

    onApiFailure = (e: Error) => {
        this._loading = false;
        throw e;
    }

    onApiFailureUserCreate = (e: Error) => {
        const errorMessageJSON = JSON.parse(e.message);
        const errorMessageText = Object.values(errorMessageJSON)[0]

        this._loading = false;
        throw errorMessageText;
    }

    onLoadDataSuccess = ({items, total}: { items: User[], total: number }) => {
        this._data = items;
        this._loading = false;
    }

    onLoadAccountsDataSuccess = ( response: any) => {
        this._accounts = response;
        this._loading = false;
    }

    onLoadUsersDataSuccess = ( response: any) => {
        this._users = response;
        this._loading = false;
    }

    onLoadAccountsByIdDataSuccess = ( response: any) => {
        this._accountsById = response;
        this._loading = false;
    }

    onLoadAllAlertsSuccess = ( response: any) => {
        this._alerts = response;
        this._loading = false;
    }

    loadData = (): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${AuthStore.user?.account_id}/users`, 
            options: { method: 'GET' }
        })
            .then(this.onLoadDataSuccess)
            .catch(this.onApiFailure);
    }

    loadDataById = (id: any): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${id}/users`, 
            options: { method: 'GET' }
        })
            .then(this.onLoadAccountsByIdDataSuccess)
            .catch(this.onApiFailure);
    }

    create = (data: User): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${data.account_id || AuthStore.user?.account_id}/users`, 
            options: { method: 'POST' },
            data
        })
            .then(this.loadData)
            .catch(this.onApiFailureUserCreate);
    }

    update = (data: User): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${data.account_id || AuthStore.user?.account_id}/users/${data.id}`, 
            options: { method: 'PATCH' },
            data
        })
            .then(this.loadData)
            .catch(this.onApiFailure);
    }

    delete = (data: User): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts/${AuthStore.user?.account_id}/users/${data.id}`, 
            options: { method: 'DELETE' },
            data
        })
            .then(this.loadData)
            .catch(this.onApiFailure);
    }

    getAllAcounts = (): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/accounts`,
            options: { method: 'GET' },
        })
            .then(this.onLoadAccountsDataSuccess)
            .catch(this.onApiFailure)
    }

    getAllUsers = (): Promise<any> => {
        this._loading = true;

        return makeAuthenticatedRequest({
            url: `/api/v2/users`,
            options: { method: 'GET' },
        })
            .then(this.onLoadUsersDataSuccess)
            .catch(this.onApiFailure)
    }
}

const STORE = new UsersStore();

export default STORE;