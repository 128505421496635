import AuthStore from './AuthStore';
import DevicesStore from './DevicesStore';
import DeviceDetailStore from './DeviceDetailStore';
import WindowsConfigStore from './WindowsConfigStores';
import UserProfileStore from './UserProfileStore';
import UsersStore from './UsersStore';
import AlertsStore from './AlertsStore';
import ProcessesPerUserStore from './ProcessesPerUserStore';
import AccountsStore from './AccountsStore'
import ExclusionListsStore from './ExclusionListsStore';

export default {
    AccountsStore,
    AuthStore,
    DevicesStore,
    DeviceDetailStore,
    WindowsConfigStore,
    UserProfileStore,
    UsersStore,
    AlertsStore,
    ProcessesPerUserStore,
    ExclusionListsStore
};